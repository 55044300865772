import React from 'react';
import { useCallback, useEffect, useRef } from 'react';

type Props = {
  action: (isIntersecting?: boolean) => void;
};

export default function InfinityScrollLoader({ action }: Props) {
  const loader = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      action(target.isIntersecting);
    }
  }, []);

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') return;
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader && loader.current && observer) observer.unobserve(loader.current);
    };
  }, [handleObserver]);

  return <div ref={loader}></div>;
}
